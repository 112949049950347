import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<div className="loader-wrapper"><div className="loader"></div></div>}
    
    >
      <Component {...props} />
    </Suspense>
  );
};

function AppRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { element: <Home replace />, index: true },
        { path: "about", element: <About /> },
        { path: "privacypolicy", element: <PrivacyPolicy /> },
        { path: "termsandconditions", element: <TermsAndConditions /> },
        { path: "services", element: <Services /> },
        { path: "servicedetail/:id", element: <ServiceDetail /> },
        { path: "work", element: <Work /> },
        { path: "workdetail/:id", element: <WorkDetail /> },
        { path: "career", element: <Career /> },
        { path: "blogs", element: <Blogs /> },
        { path: "blogdetail/:id", element: <BlogDetail />},
        { path: "gallery", element: <Gallery /> },
        { path: "contact", element: <Contact /> }
      ],
    },
  ]);

  return routes;
}


export default function RouterComponent() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

// components
const Layout = Loadable(lazy(() => import("../Components/Layout/Layout")));
const Home = Loadable(lazy(() => import("../Components/Home/Home")));
const About = Loadable(lazy(() => import("../Components/About/About")));
const PrivacyPolicy = Loadable(lazy(() => import("../Components/About/PrivacyPolicy")));
const TermsAndConditions = Loadable(lazy(() => import("../Components/About/TermsAndConditions")));
const Services = Loadable(lazy(() => import("../Components/Services/Services")));
const ServiceDetail = Loadable(lazy(() => import("../Components/Services/ServiceDetail")));
const Work = Loadable(lazy(() => import("../Components/Work/Work")));
const WorkDetail = Loadable(lazy(() => import("../Components/Work/WorkDetail")));
const Career = Loadable(lazy(() => import("../Components/Career/Career")));
const Blogs = Loadable(lazy(() => import("../Components/Blogs/Blog")));
const BlogDetail = Loadable(lazy(() => import("../Components/Blogs/BlogDetail")));
const Gallery = Loadable(lazy(() => import("../Components/Gallery/Gallery")));
const Contact = Loadable(lazy(() => import("../Components/Contact/Contact")));